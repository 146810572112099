import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './IJCCIServices.css';

const services = [
  {
    title: "Business Networking Events",
    description: "Connecting Indian and Japanese businesses through trade fairs, seminars, and networking events.",
    image: "https://images.unsplash.com/photo-1563986768609-322da13575f3?w=500&auto=format&fit=crop&q=60"
  },
  {
    title: "Trade & Investment Facilitation",
    description: "Helping businesses navigate regulatory compliance, market entry strategies, and investment opportunities.",
    image: "https://images.unsplash.com/photo-1605902711622-cfb43c4437b5?w=500&auto=format&fit=crop&q=60"
  },
  {
    title: "Cultural Exchange Programs",
    description: "Encouraging collaboration through language education, corporate training, and sponsored programs.",
    image: "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?w=500&auto=format&fit=crop&q=60"
  },
  {
    title: "Policy Advocacy",
    description: "Working with governments to improve trade policies for a better business climate.",
    image: "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?w=500&auto=format&fit=crop&q=60"
  },
  {
    title: "Market Research & Business Intelligence",
    description: "Providing reports and insights on India-Japan market trends and business dynamics.",
    image: "https://images.unsplash.com/photo-1593642532400-2682810df593?w=500&auto=format&fit=crop&q=60"
  },
  {
    title: "Technology Transfer & Innovation",
    description: "Facilitating joint ventures to enhance productivity and competitiveness.",
    image: "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?w=500&auto=format&fit=crop&q=60"
  }
];

const IJCCIServices = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="services-container">
      <h2 className="services-heading" data-aos="fade-down">Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card" data-aos="fade-up">
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="service-content">
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IJCCIServices;