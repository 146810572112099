import React, { useState } from "react";
import "./AboutUs.css";

const teamMembers = [
  { name: "Mr. Rahul Mishra", position: "Chairman", image: "WhatsApp Image 2025-03-21 at 11.36.51.jpeg" },
  { name: "Mr. Gajendra Badgujar", position: "Vice-Chairman (Strategy)", image: "WhatsApp Image 2025-03-21 at 11.35.42.jpeg" },
  { name: "Mr.Prakash Yadav", position: "Vice-Chairman (Corporate Affair)", image: "WhatsApp Image 2025-03-21 at 11.36.11.jpeg" },
  { name: "Mr. Surajit Kalita ", position: "Vice-Chairman (North East)", image: "WhatsApp Image 2025-03-21 at 11.34.24.jpeg" },
  { name: "Dr.Neelam ", position: "Vice-Chairman (Education)", image: "WhatsApp Image 2025-03-24 at 12.26.54 (1).jpeg" },
  { name: "Mr.Mohit Srivastava", position: "Treasurer", image: "WhatsApp Image 2025-03-21 at 11.38.27.jpeg" },
  { name: "Dr.Jatinder Khanna ", position: "Policy Maker (Education & Culture)", image: "WhatsApp Image 2025-03-21 at 11.37.21.jpeg" },
  { name: "Mr.Anuruddha Seneviratne", position: "Executive Director Japan", image: "WhatsApp Image 2025-03-24 at 11.39.05.jpeg" },
  { name: "Mrs.Anjali Mishra", position: "Advocate Supreme Court,India", image: "WhatsApp Image 2025-03-21 at 11.39.40.jpeg" },
  { name: "Dr. Supratic Gupta", position: "Advisor", image: "WhatsApp Image 2025-03-21 at 11.34.59.jpeg" },
  { name: "Dr.Randeep Rakwal", position: "Advisor", image: "WhatsApp Image 2025-03-25 at 12.28.47.jpeg" },
  { name: "Mr.Naveen Verma ", position: "Advisor", image: "WhatsApp Image 2025-03-25 at 12.28.47 (2).jpeg" },
  { name: "Dr.Maushumi Barooah ", position: "Advisor", image: "WhatsApp Image 2025-03-25 at 12.28.46.jpeg" },
  { name: "Mr.Lokendra Kumar Rana", position: "Founding Member", image: "WhatsApp Image 2025-03-21 at 11.33.52.jpeg" },
  { name: "Mr.Pradeep Agarwal", position: "Founding Member", image: "WhatsApp Image 2025-03-21 at 11.37.56.jpeg" },
  { name: "Mr.Raj Singh", position: "Founding Member", image: "WhatsApp Image 2025-03-21 at 11.38.58.jpeg" },
  { name: "Mr. M Nazir ", position: "Founding Member", image: "WhatsApp Image 2025-03-21 at 12.03.34.jpeg" },
];

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("about");

  return (
    <div className="about-container">
      {/* Navigation Tabs */}
      <div className="tabs">
        <button className={activeTab === "about" ? "active" : ""} onClick={() => setActiveTab("about")}>ABOUT US</button>
        <button className={activeTab === "vision" ? "active" : ""} onClick={() => setActiveTab("vision")}>VISION</button>
        <button className={activeTab === "mission" ? "active" : ""} onClick={() => setActiveTab("mission")}>MISSION</button>
        <button className={activeTab === "team" ? "active" : ""} onClick={() => setActiveTab("team")}>OUR TEAM</button>
      </div>

      {/* Content Section */}
      <div className="about-content">
      {activeTab === "about" && (
        <div className="section">
          <div className="text">
            <h2>ABOUT US</h2>
            <p>
              Indo-Japan Chamber of Commerce  (IJCC) was formed with a view to promoting closer economic relations between India and Japan. The Chamber has facilitated several business interactions, forged partnerships, and promoted bilateral trade over the years. We work to enhance trade relations, attract investment, and create platforms for business success in both countries.
            </p>
            <p>
              The Chamber focuses on collaboration in manufacturing, technology, infrastructure, and services. IJCC helps guide the policies that impact both the Indian and Japanese economies by partnering with government initiatives on both sides, companies, and industry leaders.
            </p>
            <h3>Key Focus Areas:</h3>
            <div className="focus-areas">
              <ul>
                <li>Business Promotions</li>
                <li>Bilateral Trade</li>
                <li>Seminars</li>
                <li>Symposium</li>
              </ul>
              <ul>
                <li>Investment Opportunities</li>
                <li>Buyer-Seller Meets</li>
                <li>Workshops</li>
                <li>Trade Visits</li>
              </ul>
            </div>
          </div>
          <img src="https://images.unsplash.com/photo-1519185939676-2605343f3d4d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGluZGlhJTIwamFwYW58ZW58MHx8MHx8fDA%3D" alt="About Us" className="section-image" />
        </div>
      )}

      {activeTab === "vision" && (
        <div className="section">
          <div className="text">
            <h2>VISION</h2>
            <p>
              Our goal is to achieve a vibrant ecosystem of the Indo-Japanese economy and business enterprises, fostering growth, innovation, and sustainable partnerships. Through strategic collaborations, we envision building a future where trade and investments between India and Japan flourish, benefiting industries, professionals, and communities.
            </p>
          </div>
          <img src="https://images.unsplash.com/photo-1660111451643-471eadf1dd58?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGluZGlhJTIwamFwYW58ZW58MHx8MHx8fDA%3D" alt="Vision" className="section-image" />
        </div>
      )}

      {activeTab === "mission" && (
        <div className="section">
          <div className="text">
            <h2>MISSION</h2>
            <p>
              We aim to be the best bridge to build business connections between India and Japan, providing platforms for trade, investment, and cultural collaboration. Our mission is to create opportunities for professionals, entrepreneurs, and businesses to connect, share knowledge, and grow in a dynamic and competitive environment.
            </p>
          </div>
          <img src="https://images.unsplash.com/photo-1536098561742-ca998e48cbcc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njl8fGluZGlhJTIwamFwYW58ZW58MHx8MHx8fDA%3D" alt="Mission" className="section-image" />
        </div>
      )}

        {activeTab === "team" && (
          <div className="section">
            <div className="text">
              <h2>OUR TEAM</h2>
              <p>
                Our team consists of dedicated professionals working tirelessly to foster stronger ties between India and Japan.
              </p>
            </div>
            <div className="image-gallery">
              {teamMembers.map((member, index) => (
                <div key={index} className="team-member">
                  <img src={`./images/${member.image}`} alt={member.name} className="team-image" />
                  <p className="team-name" style={{ marginBottom: "2px" }}>{member.name}</p>
<h4 className="team-name1" style={{ marginTop: "2px", fontSize: "14px", color: "#555" }}>{member.position}</h4>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutUs;