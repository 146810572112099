import React, { useState } from "react";
import "./MembershipForm.css";
import { database } from "../firebase.js"; // Ensure the correct path
import { ref, push } from "firebase/database"; // Import from "firebase/database"
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Add storage imports
import { useNavigate } from "react-router-dom";
const MembershipForm = () => {
  const navigate = useNavigate();
  const [membershipType, setMembershipType] = useState("");
  const [formData, setFormData] = useState({
    // Member/Nominee Details
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    country: "India",
    state: "",
    city: "",
    pincode: "",
    nationality: "",
    designation: "",
    panOrPassportOrDL: "",
    // Organization Details
    companyName: "",
    industry: "",
    website: "",
    companyEmail: "",
    contactNo1: "",
    contactNo2: "",
    companyAddress: "",
    annualTurnover: "",
    revenueProof: null,
    gstNumber: "",
    companyPanCard: "",
    // MD/CEO Details
    mdFirstName: "",
    mdLastName: "",
    mdEmail: "",
    mdMobile: "",
    mdAddress: "",
    mdCountry: "India",
    mdState: "",
    mdCity: "",
    mdPincode: "",
    mdNationality: "",
    mdDesignation: "",
    // Student Details
    instituteName: "",
    collegeIdCard: null,

    // Areas of Interest
    areasOfInterest: "",
  });

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Remove file inputs and send only text data
    const formDataToSend = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => !(value instanceof File))
    );
  
    try {
      const response = await fetch("https://ijcc.onrender.com/submit-form", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formDataToSend),
      });
  
      const result = await response.json();
      console.log("Response from server:", result);
  
      if (result.status === "Success") {
        alert("Your information has been successfully submitted. We will verify it and get back to you shortly.");
        navigate("/"); 
      } else {
        alert("Error: " + result.message);
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("Your information has been successfully submitted. We will verify it and get back to you shortly.");
    }
  };
  return (
    <form onSubmit={handleSubmit} className="membership-form">
      <h2>Indo-Japan Chamber of Commerce (IJCC) Membership Request Form</h2>
      <p>
        I/We would like to apply for membership to the Indo-Japan Chamber of Commerce (IJCC). If selected, we agree to abide by the TRUST deed and rules framed thereunder from time to time.
      </p>
      <p>
        <strong>Note:</strong> This is just the initial application. Upon
        receiving a confirmation email, membership fees will need to be paid.
      </p>

      {/* Membership Type */}
      <label>
        Membership Type*
        <select
          name="membershipType"
          value={membershipType}
          onChange={(e) => setMembershipType(e.target.value)}
          required
        >
          <option value="">Please Select</option>
          <option value="Chambers/Industrial Association">
            Chambers/Industrial Association
          </option>
          <option value="Corporate Associate">Corporate Associate</option>
          <option value="Education Institute/University">
            Education Institute/University
          </option>
          <option value="Individual Associate">Individual Associate</option>
          <option value="Student">Student</option>
        </select>
      </label>

      {membershipType && (
        <>
          <h3>Contacts of Member or Nominee</h3>
          <label>First Name* <input type="text" name="firstName" required onChange={handleInputChange} /></label>
          <label>Last Name* <input type="text" name="lastName" required onChange={handleInputChange} /></label>
          <label>Email Id* <input type="email" name="email" required onChange={handleInputChange} /></label>
          <label>Mobile No* <input type="tel" name="mobile" required onChange={handleInputChange} /></label>
          <label>Address* <textarea name="address" required onChange={handleInputChange} /></label>
          <label>Country* <input type="text" name="country" value="India" disabled /></label>
          <label>State* <input type="text" name="state" required onChange={handleInputChange} /></label>
          <label>City* <input type="text" name="city" required onChange={handleInputChange} /></label>
          <label>Pincode* <input type="text" name="pincode" required onChange={handleInputChange} /></label>
          <label>Nationality* <input type="text" name="nationality" required onChange={handleInputChange} /></label>
          <label>Designation* <input type="text" name="designation" required onChange={handleInputChange} /></label>
          <label>PAN or Passport or Driving License* <input type="text" name="panOrPassportOrDL" required onChange={handleInputChange} /></label>

          {membershipType !== "Student" && (
            <>
              <h3>Organization Details</h3>
              <label>Company Name* <input type="text" name="companyName" required onChange={handleInputChange} /></label>
              <label>Industry* <input type="text" name="industry" required onChange={handleInputChange} /></label>
              <label>Website <input type="text" name="website" onChange={handleInputChange} /></label>
              <label>Email Id* <input type="email" name="companyEmail" required onChange={handleInputChange} /></label>
              <label>Contact No. 1* <input type="tel" name="contactNo1" required onChange={handleInputChange} /></label>
              <label>Contact No. 2 <input type="tel" name="contactNo2" onChange={handleInputChange} /></label>
              <label>Address* <textarea name="companyAddress" required onChange={handleInputChange} /></label>
              <label>Annual Turnover* <input type="text" name="annualTurnover" required onChange={handleInputChange} /></label>
              <label>Upload Revenue Proof* <input type="file" name="revenueProof" required onChange={handleInputChange} /></label>
              <label>GST Number <input type="text" name="gstNumber" onChange={handleInputChange} /></label>
              <label>PAN Card* <input type="text" name="companyPanCard" required onChange={handleInputChange} /></label>

              <h3>Contacts of MD/CEO</h3>
              <label>First Name* <input type="text" name="mdFirstName" required onChange={handleInputChange} /></label>
              <label>Last Name* <input type="text" name="mdLastName" required onChange={handleInputChange} /></label>
              <label>Email Id* <input type="email" name="mdEmail" required onChange={handleInputChange} /></label>
              <label>Mobile No* <input type="tel" name="mdMobile" required onChange={handleInputChange} /></label>
            </>
          )}

          {membershipType === "Student" && (
            <>
              <h3>Student Details</h3>
              <label>Institute Name* <input type="text" name="instituteName" required onChange={handleInputChange} /></label>
              <label>Upload College ID Card* <input type="file" name="collegeIdCard" required onChange={handleInputChange} /></label>
            </>
          )}

          <h3>Areas of Interest with Japan/India</h3>
          <label><textarea name="areasOfInterest" onChange={handleInputChange} /></label>

          <button type="submit">Submit</button>
        </>
      )}
    </form>
  );
};

export default MembershipForm;