import React from "react";
import "./FocusAreas.css";

const FocusAreas = () => {
  const areas = [
    { title: "Business Networking", image: "https://images.unsplash.com/photo-1515169067868-5387ec356754?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8QnVzaW5lc3MlMjBOZXR3b3JraW5nfGVufDB8fDB8fHww" },
    { title: "Trade and Investment", image: "https://images.unsplash.com/photo-1507679799987-c73779587ccf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQwfHxUcmFkZSUyMGFuZCUyMEludmVzdG1lbnR8ZW58MHx8MHx8fDA%3D" },
    { title: "Cultural Exchange", image: "https://images.unsplash.com/photo-1524413840807-0c3cb6fa808d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Q3VsdHVyYWwlMjBFeGNoYW5nZSUyMGphcGFuJTIwaW5kaWF8ZW58MHx8MHx8fDA%3D" },
    { title: "Education", image: "https://images.unsplash.com/photo-1470256699805-a29e1b58598a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fEVkdWNhdGlvbiUyMGphcGFuJTIwaW5kaWF8ZW58MHx8MHx8fDA%3D" },
    { title: "Policy Advocacy", image: "https://images.unsplash.com/photo-1683200107031-e720a3fddf57?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fFBvbGljeSUyMEFkdm9jYWN5fGVufDB8fDB8fHww" },
    { title: "Overseas Placement Support", image: "https://images.unsplash.com/photo-1591980607162-923fa31e8240?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fE92ZXJzZWFzJTIwUGxhY2VtZW50JTIwU3VwcG9ydHxlbnwwfHwwfHx8MA%3D%3D" },
  ];

  return (
    <div className="focus-container">
      <h2>Key Areas of Focus</h2>
      <p className="ijcc-text">
    Indo-Japan Chamber of Commerce is a platform that connects Indian and
    Japanese economy and culture and bridges their strong economic and
    cultural ties.
  </p>
  <p className="ijcc-text">
    Let's join together to foster cross-border trade, innovation, and
    strategic partnerships that can lead to sustainable economic growth
    for both countries.
  </p>
      <div className="focus-grid">
        {areas.map((area, index) => (
          <div
            className="focus-box"
            key={index}
            style={{ backgroundImage: `url(${area.image})` }}
          >
            <div className="focus-overlay">
              <span>{area.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FocusAreas;