import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Component/Navbar";
import IJCC from "./Component/IJCC";
import FocusAreas from "./Component/FocusAreas";
import AboutUs from "./Component/AboutUs";
import Services from "./Component/Services";
import Membership from "./Component/Membership";
import Events from "./Component/Events";
import ContactUs from "./Component/ContactUs";
import MembershipForm from "./Component/Form";
import IJCCIServices from "./Component/Services";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<><IJCC /><FocusAreas /><AboutUs/><IJCCIServices/><Membership/><Events/><ContactUs/></>} />
        <Route path="/form" element={<MembershipForm/>} />
        <Route path="/focus-areas" element={<FocusAreas />} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/service" element={<IJCCIServices/>} />
        <Route path="/members" element={<Membership/>} />
        <Route path="/event-gallery" element={<Events/>} />
        <Route path="/contact" element={<ContactUs/>} />
      </Routes>
    </Router>
  );
}

export default App;