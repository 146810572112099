import React, { useState } from "react";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:info@ijcci.org?subject=Contact from ${encodeURIComponent(
      formData.name
    )}&body=Name: ${encodeURIComponent(formData.name)}%0D%0AEmail: ${encodeURIComponent(
      formData.email
    )}%0D%0AMessage: ${encodeURIComponent(formData.message)}`;

    window.location.href = mailtoLink;
    setSubmitted(true);
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <p>Get in touch with us for any queries or collaboration opportunities.</p>
      
      <div className="contact-details">
        <p>📧 <strong>Email:</strong> <a href="mailto:info@ijcci.org">info@ijcci.org</a></p>
        <p>📞 <strong>Phone (India):</strong> +91-9599301261</p>
        <p>📍 <strong>India Address:</strong> 823, Gaur Global Village, Crossing Republic, Ghaziabad, Uttar Pradesh (Delhi NCR) Pin Code: 201016</p>
        
        <hr />

        <p>📞 <strong>Phone (Japan):</strong> +81-80-9055-1930</p>
        <p>📍 <strong>Japan Address:</strong> Shinagawa-ku, Kita Shinagawa 3-3-6-704, Tokyo 140-0001, Japan</p>
      </div>
      <h3>Follow Us</h3>
      <div className="social-links">
        <p>📌 <a href="https://www.facebook.com/share/1BqB9qNGPw/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer">Follow us on Facebook</a></p>
        <p>📸 <a href="https://www.instagram.com/ijccindia?igsh=MWFwamcyMHFqNGJtZg==&utm_source=ig_contact_invite" target="_blank" rel="noopener noreferrer">Follow us on Instagram</a></p>
      </div>

      <h3>Send Us a Message</h3>
      {submitted ? (
        <p className="success-message">✅ Thank you! Your email is ready to send.</p>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <label>Name</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />

          <label>Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />

          <label>Message</label>
          <textarea name="message" value={formData.message} onChange={handleChange} required />

          <button type="submit">Send Message</button>
        </form>
      )}
    </div>
  );
};

export default ContactUs;