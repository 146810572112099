import React, { useState } from "react";
import "./Events.css";

const Events = () => {
  const [activeTab, setActiveTab] = useState("upcoming");

  return (
    <div className="events-container">
      {/* Navigation Tabs */}
      <div className="tabs">
        <button className={activeTab === "upcoming" ? "active" : ""} onClick={() => setActiveTab("upcoming")}>UPCOMING EVENTS</button>
        <button className={activeTab === "past" ? "active" : ""} onClick={() => setActiveTab("past")}>PAST EVENTS</button>
      </div>

      {/* Content Section */}
      <div className="events-content">
        {activeTab === "upcoming" && (
          <>
            <h2>Upcoming Events</h2>
            <p>Stay connected with our community by participating in our upcoming events.</p>
            <div className="event">
              <h3>📌 Indo-Japan Business Summit 2025</h3>
              <p><strong>Date:</strong> [Insert Date] | <strong>Location:</strong> [Insert Location]</p>
              <p>A premier event bringing together leaders from India and Japan to discuss opportunities in trade, technology, and innovation.</p>
            </div>

            <div className="event">
              <h3>🎭 Cultural Exchange Program: India-Japan Collaboration</h3>
              <p><strong>Date:</strong> [Insert Date] | <strong>Location:</strong> [Insert Location]</p>
              <p>An immersive experience for businesses to explore cultural nuances while understanding market opportunities.</p>
            </div>

            <div className="event">
              <h3>🤝 B2B Networking Evening</h3>
              <p><strong>Date:</strong> [Insert Date] | <strong>Location:</strong> [Insert Location]</p>
              <p>An informal yet productive networking evening with industry professionals from both countries.</p>
            </div>

            <div className="event">
              <h3>🎌 Konnichiwa Japan Festival</h3>
              <p><strong>Date:</strong> [Insert Date] | <strong>Location:</strong> [Insert Location]</p>
              <p>An immersive experience of Japanese culture through performances, cuisine, and interactive activities.</p>
            </div>
          </>
        )}

        {activeTab === "past" && (
          <>
            <h2>Past Events</h2>
            <p>Explore our past events that have fostered Indo-Japanese collaborations.</p>
            <div className="event">
              <h3>🏆 Indo-Japan Tech Innovation Summit 2024</h3>
              <p><strong>Date:</strong> January 2024 | <strong>Location:</strong> New Delhi, India</p>
              <p>A summit focused on AI, IoT, and cross-border tech collaboration.</p>
            </div>

            <div className="event">
              <h3>🌍 Indo-Japan Sustainability Forum</h3>
              <p><strong>Date:</strong> September 2024 | <strong>Location:</strong> Tokyo, Japan</p>
              <p>An event focusing on green technology and sustainable business solutions.</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Events;