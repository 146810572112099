import React from "react";
import './IJCC.css';

const HazyImage = () => {
  return (
    <div className="hazy-container">
      <h1 className="title">INDO-JAPAN CHAMBER OF COMMERCE</h1>
    </div>
  );
};


export default HazyImage;