import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAjxIWEUp6WvXaRgWogXPGq94NlrcdeGTg",
  authDomain: "ijcc-23300.firebaseapp.com",
  projectId: "ijcc-23300",
  storageBucket: "ijcc-23300.appspot.com",
  messagingSenderId: "312625235201",
  appId: "1:312625235201:web:2144a675fe1a24c7ff0700",
  measurementId: "G-HWRYYXCNRH",
  databaseURL: "https://ijcc-23300-default-rtdb.firebaseio.com/", // Add database URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };