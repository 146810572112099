import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
    <div className="logo" style={{ cursor: "pointer" }}>
    <a href="/">
      <img 
        src="/images/JPG_LOGO-removebg-preview.png" 
        alt="INJACK Logo" 
      />
    </a>
  </div>
      
      {/* Hamburger Icon */}
      <div className={`hamburger ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* Navigation Links */}
      <ul className={`nav-links ${isOpen ? "show" : ""}`}>
        <li>
          <NavLink to="/" className="nav-link" activeclassname="active" onClick={toggleMenu}>HOME</NavLink>
        </li>
        <li>
          <NavLink to="/about" className="nav-link" activeclassname="active" onClick={toggleMenu}>ABOUT US</NavLink>
        </li>
        <li>
          <NavLink to="/focus-areas" className="nav-link" activeclassname="active" onClick={toggleMenu}>FOCUS AREAS</NavLink>
        </li>
        <li>
          <NavLink to="/service" className="nav-link" activeclassname="active" onClick={toggleMenu}>SERVICES</NavLink>
        </li>
        <li>
          <NavLink to="/event-gallery" className="nav-link" activeclassname="active" onClick={toggleMenu}>EVENT GALLERY</NavLink>
        </li>
        <li>
          <NavLink to="/members" className="nav-link" activeclassname="active" onClick={toggleMenu}>MEMBERS</NavLink>
        </li>
        <li>
          <NavLink to="/contact" className="nav-link" activeclassname="active" onClick={toggleMenu}>CONTACT US</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;