import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Membership.css";

const membershipData = {
  benefits: {
    title: "Membership Benefits",
    image: "https://images.unsplash.com/photo-1501560379-05951a742668?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGluZGlhJTIwamFwYW4lMjBnaXZlfGVufDB8fDB8fHww",
    content: [
      "Exclusive networking events",
      "Business forums and roundtable discussions",
      "Market research support",
      "Regulatory assistance",
      "Advocacy on policy matters",
      "Cultural exchange opportunities"
    ]
  },
  corporate: {
    title: "Corporate Membership",
    image: "https://images.unsplash.com/photo-1504416285472-eccf03dd31eb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzJ8fGluZGlhJTIwamFwYW4lMjBnaXZlfGVufDB8fDB8fHww",
    content: "Designed for companies that wish to expand their business and collaborate with Indo-Japanese organizations."
  },
  individual: {
    title: "Individual Membership",
    image: "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?w=800",
    content: "Ideal for professionals seeking bilateral business engagement and industry insights."
  },
  sme: {
    title: "Startups & SME Membership",
    image: "https://images.unsplash.com/photo-1542744173-05336fcc7ad4?w=800",
    content: "Tailored for small businesses and startups aiming to scale in the Indo-Japanese market."
  },
  education: {
    title: "Education & University Membership",
    image: "https://images.unsplash.com/photo-1571260899304-425eee4c7efc?w=800",
    content: "Fosters knowledge exchange and strengthens Indo-Japanese ties in education, culture, and trade."
  }
};

const Membership = () => {
  const [activeTab, setActiveTab] = useState("benefits");
  const navigate = useNavigate();

  return (
    <div className="membership-container">
      {/* Navigation Tabs */}
      <div className="tabs">
        {Object.keys(membershipData).map((tab) => (
          <button
            key={tab}
            className={activeTab === tab ? "active" : ""}
            onClick={() => setActiveTab(tab)}
          >
            {tab.toUpperCase()}
          </button>
        ))}
      </div>

      {/* Content Section */}
      <div className="membership-content">
        <img
          src={membershipData[activeTab].image}
          alt={membershipData[activeTab].title}
          className="membership-image"
        />
        <h2>{membershipData[activeTab].title}</h2>
        {Array.isArray(membershipData[activeTab].content) ? (
          <ul>
            {membershipData[activeTab].content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <p>{membershipData[activeTab].content}</p>
        )}
      </div>

      {/* Join Now Button */}
      <div className="join-now">
        <button onClick={() => navigate("/form")}>Join Now</button>
      </div>
    </div>
  );
};

export default Membership;